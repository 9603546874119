// Place all the styles related to the applications controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

html {
  height: 100%;
}

body {
  padding-top: 95px;
  height: 100%;
}

@media (max-width: 1200px) {
  body { padding-top: 110px; }
}

@media (max-width: 980px) {
  body { padding-top: 160px; }
}

@media (max-width: 770px) {
  body { padding-top: 60px; }

  .large-screen-only {
    display: none;
  }
}

@media (min-width: 770px) {
  div.mobile-only {
    display: none;
  }
}

.vpsb {
  .select-container-wrapper {
    min-height: 90px;
  }

  .timestamp {
    font-style: italic;
    margin-bottom: 10px;
  }

  .main-container {
    min-height: 100%;
    margin-bottom: -352px;
  }

  .sticky-bottom, .push {
    margin-top: 30px;
    height: 322px;
    width: 100%;
    z-index: 100;
    position: sticky;
  }

  .screenshot {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width:100%;
    max-height:100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .large-font {
    font-size: 1.4rem;
  }

  .full-height {
    height: 100%;
  }
}


.banner {
  background-color: #FFF;
  padding: 20px;
  //border-bottom:3px solid #D83;
  margin-bottom: 10px;

  #title {
    font-size: 40px;
    font-weight: bold;
    padding: 1px;
    color: #D22;
  }

  #subtitle {
    font-size: 15px;
    font-style: italic;
    font-weight: bold;
    padding: 1px;
    color: #666;
  }
}

.ad-page-top {
  padding-top: 15px;
}

.navbar-inverse.vpsb-navbar .navbar-nav > li > a {
  color: #aeaeae;
}

div.twitter-list {
  hr {
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 2px;
  }
}

.vpsb {
  h3.h3-black {
    font-weight: 600;
    color: #000;
  }
}


.row .vertical-align {
    display: flex;
    align-items: center;
}

.row .valign-middle {
  display: table-cell !important;
  vertical-align: middle !important;
}

.horizontal-center {
    margin-left: auto;
    margin-right: auto;
    width: 90%
}

ul.sidebar_menu {
  padding-left: 10px;

  li {
    padding: 3px;
  }

  a {
    font-size: 16px;
  }

}

ul.no-indent {
  padding-left: 13px;
}

ul.indent-sm {
  padding-left: 18px;
}

ol.no-indent {
  padding-left: 20px;
}

ul.no-bullet {
  list-style: none;
  padding-left: 0rem;
}

ul.plain {
  list-style: none;
  padding: 0 0 5px 0;
}


ul.high-padding li {
  margin-top: 10px;
  margin-bottom: 10px;
}

ul.based-on {
  padding-top: 0px;
  padding-left: 15px;
}

ul.two_columns {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
}

ul.min-width-columns {
  -webkit-column-width: 150px;
  -moz-column-width: 150px;
       column-width: 150px;
}

div.two_columns {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
}

div.three_columns {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 3; /* Firefox */
    column-count: 3;
}

.form-group.no-margin-bottom {
  margin-bottom: 5px; // instead of 15px for form-group
}

.sidebar_title {
  font-weight: bold;
  font-size: 18px;
  color: #D83;
}

.red {
  color: #D22
}


#menu {
  width: 160px;
  position: absolute;
  left: 10px;
  top: 10px;
  bottom: 20px;
  float: left;
  border-right-style: solid;
  border-right-color: #CCC;
  border-right-width: thin;
}

#content {
  width: 700px;
  float:left;
  margin-left: 195px;
}

#footer {
  .footer-line-ad a {
    font-size: 1rem !important;
  }
}

#recent-trials {
  width: 100%
}

.share-button {
  margin: 5px auto;
  float: right;
}

.vpsb {

  .vert-margin {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .vert-margin-high {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .horiz-margin {
    margin-left: 5px;
    margin-right: 5px;
  }

  .review-compare-panel {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.thumbnail-screenshot {
  min-height: 250px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.grade-ab-background {
  background-color: #DFD;
}

.grade-c-background {
  background-color: #DFF0D8;
}

.grade-de-background {
  background-color: #FCF8E3;
}

.grade-f-background {
  background-color: #F2DEDE;
}

.grade-null-background {
  background-color: var(--vpsb-background);
  color: var(--vpsb-font-color) !important;
}


.light-green-background {
  background-color: #DFD;
}

.light-blue-background {
  background-color: #e6f2ff;
}

.light-orange-background {
  background-color: var(--vpsb-secondary-light);
  color: var(--vpsb-almost-black);
}

.alert-large {
  font-size: 20px;
}

.vpsb .alert-light-orange {
  background-color: var(--vpsb-primary-extra-muted);
  border-color: var(--vpsb-primary-muted);
  border-width: 1px;
  color: var(--vpsb-font-color-strong);
  font-size: 16px;
}

.vpsb .alert {
  margin-top: 10px;

  &.alert-warning {
    a {
      color: var(--vpsb-primary-medium-light);
    }
  }
}

.panel-heading {
  .panel-title, .title {
    font-size: 16px;
    font-weight: 600;
  }
}

.panel-body.wide-ho-padding {
    padding-left: 25px;
    padding-right: 25px;
}

.vpsb {
  .box-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .box-shadow-colored {
    @extend .box-shadow;
    background-color: var(--vpsb-primary-muted);
    font-weight: 700 !important;
    color: var(--vpsb-primary-strong);
  }
}

.vpsb {
  .inline-select {
    display: inline-block;
    width: auto;
  }
}

.d-flex > div.valign-center {
  display: flex;
  align-items: center;
}

.d-flex > div.halign-right {
  display: flex;
  justify-content: flex-end;
}

.flex-vcenter {
  display: flex;
  align-items: center;
}

.flex-hright {
  display: flex;
  justify-content: flex-end;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.d-flex {
  &.with-gap {
    gap: 7%;
  }

  &.with-gap-sm {
    gap: 3%;
  }

  &.with-gap-xs {
    gap: 1%;
  }

  &.flex-equal-width > * {
    flex: 1;
  }
}

.d-flex > .flex-dyn-wrap {
  flex: 1 0 150px;
}

.flex-button-row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  a.btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-equal-height {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  .equal-width {
    flex: 1;
    margin-bottom: 1rem;
  }
}

.button-row-container {
  margin-left: -10px;
  margin-right: -10px;

  .flex-item-grow {
    flex: 1;
    margin: 10px;
  }
}

.aligned-boxes-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  .full-width-col {
    flex: 1 0 250px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

#footer section.footer {
  padding: 30px;
  //border-bottom:3px solid #D83;
  margin: 0px;
  min-height: 100%;
  color: var(--vpsb-background-secondary);
  background-color: var(--vpsb-background-soft);

  .small-text, .small-text a {
    font-size: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  html[data-theme="light"] & {
    color: var(--vpsb-font-color-inverse);
    background-color: var(--vpsb-darkish-gray);

    a {
      color: var(--vpsb-link-color-strong-bg);
    }
  }

  html[data-theme="dark"] & {
    color: var(--vpsb-font-color);
    background-color: var(--vpsb-background-soft);
  }
}

a.tab-title {
  font-size: 1.1rem;
}

.article-body {
  font-size: 1.2rem;
}

.bigger {
  font-size: larger;
}

.speed-in-panel {
  font-size: 24px;
  font-weight: 700;
}

#error_explanation {
  color: #f00;
  ul {
    list-style: none;
    margin: 0 0 18px 0;
  }
}

.field_with_errors {
  input,textarea,select {
    outline:none;
    border-color:#f88;
    box-shadow:0 0 10px #f88;
  }

  label {
    color: #f00;
  }
}

.vpsb .strong-font {
  font-weight: 600;
}

.vpsb {
  dl {
    font-size: 1em;
  }

  dt {
    margin-top: 10px;
  }

  dd {
    margin-bottom: 20px;
  }

  .dowrap {
    white-space: normal;
    word-break: break-word;
  }

  .nowrap {
    white-space: nowrap;
  }

  .text-vpsb-primary {
    color: var(--vpsb-primary);
  }

  .text-light-orange {
    color: #f7bb54;
  }

  .text-dark-grey {
    color:var(--vpsb-dark-gray);
  }

  .above-footer {
    margin-bottom: 50px;
  }

  .vertical-margin {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  hr.short-vmargin {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .full-width {
    width: 100%;
  }

  .logo-border {
    border-color: var(--vpsb-medium-gray);
    border-width: 0px;
    box-shadow: 1px 1px 6px rgba(64, 64, 69, 0.18);
    border-style: solid;
    border-radius: 2px;
    padding: 5px;
  }

  .white-background-frame {
    background-color: var(--vpsb-background-secondary);
    border-color: var(--vpsb-primary-muted);
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    box-shadow: 0 8px 14px rgba(13,13,18,.08);
    padding: 20px;

    &.thin-padding {
      padding: 1rem;
    }

    &.orange-border-color {
      border-color: var(--vpsb-secondary);
    }

    .lead {
      font-size: 20;
      font-style: italic;
      color: var(--vpsb-primary);
      padding-bottom: 5px;

      &.no-padding {
        padding-bottom: 0px;
        margin-bottom: 5px;
      }
    }

    h3 {
      font-size: 20;
      font-style: italic;
      color: var(--vpsb-primary);
      margin-top: 10px;
      padding-bottom: 8px;
    }

    &.no-border {
      border-width: 0;
    }

    &.max-width-md {
      max-width: 22rem;
    }
  }

  .text-ad-insert,.blue-frame {
    padding: 15px;
    background-color: var(--vpsb-background-secondary);
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    border-color: var(--vpsb-primary);
    color: var(--vpsb-font-color);
    font-size: 1.2rem;

    p {
      margin-bottom: 0px;
    }
  }

  .form-group-inline {
    display: inline-block;
  }

  .with-line-breaks {
    white-space: pre-line;
  }

  @media (min-width: 600px) {
    td.overflow-wrap-anywhere a {
      overflow-wrap: anywhere;
    }
  }

  .ssh-key-box {
    width: 100%;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
  }


  img.hoster-logo {
    max-width: 30px;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 8px;

    &.wide {
      max-width: 60px;
    }

    &.xl-wide {
      max-width: 90px;
    }

    &.xxl-wide {
      max-width: 120px;
    }
  }

  .hoster-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .provider-name-break {
    word-break: break-all;
    word-wrap: break-word;
  }

  .vpsb-ad-unit {
    border-radius: 5px;
    text-align: center;

    .vpsb-ad-heading {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 20px;
      font-size: 22px;
      font-weight: 600;
      color: white;
      background-color: var(--vpsb-primary);
    }

    .vpsb-ad-body {
      html[data-theme="dark"] & {
        background-color: var(--vpsb-background-inverse);
      }
      padding: 20px;
      font-size: 18px;
      font-weight: 600;
    }

    .no-deco {
      text-decoration: none;
      color: var(--vpsb-primary) !important;
    }

    .ad-image {
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .vpsb-square-ad-button {
    padding: 0;
    border: 0;
  }

  .plans-select-form-buttons .btn {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .first-in-sidebar {
    margin-top: 10px;
  }
}

.vpsb.dummy {
  color: #FFF;
}

.vpsb {
  .clickable-div {
    cursor: pointer;
  }

  p.circle {
    // display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid;
    text-align: center;
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);
    font-size: 1.8rem;
    margin-bottom: 0px;
  }

  .chart {
    &.chart-container-height-600 {
      height: 600px;
    }

    &.chart-container-height-450 {
      height: 450px;
    }

    &.chart-container-height-400 {
      height: 400px;
    }

    &.chart-container-height-350 {
      height: 350px;
    }

    &.chart-width-350 {
      width: 350px;
    }

    &.chart-width-400 {
      width: 400px;
    }

    &.chart-width-600 {
      width: 600px;
    }
  }

  .no-bottom-margin {
    margin-bottom: 0px;
  }

  .sidebar-button-width {
    width: 250px;
  }

  #subscribe-sidebar-popup {
    background-color: var(--vpsb-secondary-light);
    color: var(--vpsb-almost-black);

    html[data-theme=dark] & input[type=email] {
      background-color: var(--vpsb-background-inverse);
      color: var(--vpsb-font-color-inverse);
    }
  }

  @media only screen and (min-width: 760px) {
    #subscribe-sidebar-popup {
      width: 300px;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 200;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .flash-fixed-position {
    width: 500px;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 100;
  }

  @media only screen and (min-width: 1200px) {
    .flash-fixed-position {
      margin: 55px auto;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .flash-fixed-position {
      margin: 110px auto;
    }
  }
  @media only screen and (max-width: 992px) {
    .flash-fixed-position {
      width: 100%;
      margin: 55px auto;
    }
  }

  .private {
    color: red;
  }

  .plan-invisible {
    color: var(--vpsb-font-color-muted);
    font-style: italic;
  }

  .beta {
    color: red;
  }

  .grayout {
    opacity: 0.6; /* Real browsers */
    filter: alpha(opacity = 60); /* MSIE */
  }

  .hidden {
    display: none !important;
  }

  .capitalize {
    text-transform: capitalize;
  }

  input[type=checkbox].large-checkbox {
    transform: scale(1.5);
  }

  #attribution {
    &.attribution-small {
      font-size: 12px;

      a {
        font-size: 12px;
      }
    }
  }

  .private-trial-step {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: var(--vpsb-font-color-strong);

    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .search-icon-sidebar {
    font-size: 5rem;
    color: color('primary');
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .scrollable-menu-dropdown {
    overflow-x: auto;
    max-height: 770px;
  }

  .providers-menu.scrollable-menu-dropdown {
    @media (max-width: 860px) {
      -webkit-column-count: 2; /* Chrome, Safari, Opera */
      -moz-column-count: 2; /* Firefox */
      column-count: 2;
    }

    @media (min-width: 860px) {
      -webkit-column-count: 3; /* Chrome, Safari, Opera */
      -moz-column-count: 3; /* Firefox */
      column-count: 3;
    }

    @media (max-height: 750px) {
      max-height: 400px;
    }

    @media (min-height: 750px) {
      max-height: 610px;
    }

    @media (min-height: 860px) {
      max-height: 770px;
    }
  }

  .scroll-above {
    scroll-margin-top: 100px;
  }

  .left-chevron::before {
    -webkit-transform: rotate(45deg) translate3d(0,0,0);
    transform: rotate(45deg) translate3d(0,0,0);
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
    border-top-width: 3px;
    border-top-style: solid;
    border-right-width: 3px;
    border-right-style: solid;
    content: "";
    display: inline-block;
    margin-right: .3em;
    margin-bottom: .1em;
    position: relative;
    pointer-events: none;
    width: 8px;
    height: 8px;
    top: -1px;
  }

  .chevron::after {
    -webkit-transform: rotate(45deg) translate3d(0,0,0);
    transform: rotate(45deg) translate3d(0,0,0);
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
    border-top-width: 2px;
    border-top-style: solid;
    border-right-width: 2px;
    border-right-style: solid;
    content: "";
    display: inline-block;
    margin-left: .3em;
    position: relative;
    pointer-events: none;
    width: 6px;
    height: 6px;
    top: -1px;
  }

  form.inline-form {
    display: inline-block;
  }

  div.card-body, div.article-partial {
    svg .social-icon-svg {
      fill: var(--vpsb-font-color-strong);
    }
  }

  section.footer {
    svg .social-icon-svg {
      fill: var(--vpsb-light-gray);
    }
  }

  .foldable-paragraph {
    padding: 0;
    display: flex;
    align-items: flex-start;

    .fold-header {
      flex-grow: 1;
    }

    .fold-toggle {
      cursor: pointer;
      font-size: 1.5rem;
      user-select: none;
      margin-right: auto;
      margin-left: 0;
      color: var(--vpsb-primary);

      &::after {
        content: "▼";
      }

      &.folded::after {
        content: "►";
      }

    }
  }

  .fold-content {
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &.folded  {
      max-height: 0;
      transition: max-height 0.3s ease-in;
    }
  }
}
