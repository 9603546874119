.vpsb.best_vps {
  p.circle {
    // display: inline-block;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid;
    text-align: center;
    background: var(--vpsb-background-inverse);
    color: var(--vpsb-font-color-inverse);
    font-weight: 600;
    font-size: 26px;
    margin-bottom: 5px;
    margin-top: 5px;

    .ordinal {
      font-size: 10px;
    }
  }

  .thumbnail {
    .logo-wrapper {
      height: 170px;
      padding-top: 5px;
    }

    .plan-wrapper {
      height: 100px;

      h4 {
        font-size: 1.1rem;
      }
    }
  }

  .thumbnail .caption {
    color: var(--vpsb-font-color);

    h3 {
      margin-top: 0px;
    }

    h5 {
      font-weight: 600;
      font-size: 18px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .score-name {
      font-size: 18px;
    }

    .score-value {
      font-size: 50px;
      color: var(--vpsb-primary);
      line-height: 1;
    }

    .grade-block {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .grade-link {
        color: var(--vpsb-primary);
        font-weight: 600;
        font-size: 16px;
      }

      .price-weighted-subtitle {
        font-size: 6px;
      }
    }
  }

  p.category-rank {
    margin: 0px;
  }

  .grades_chart {
  }

  a.no-link-decoration {
    text-decoration: none;
    color: var(--vpsb-dark-gray);
    font-weight: 500;
  }

  .ranking-stamp {
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--vpsb-primary-muted);
    box-shadow: 0 8px 14px rgba(13,13,18,.08);
    background: var(--vpsb-background-secondary);
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 20px;

    .smaller {
      font-size: 15px;
    }

    h2 {
      margin-top: 30px;
    }

    h4 {
      color: #337ab7;
    }

    .logos-row {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      align-items: flex-end;
    }

    .logo-and-score-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .logo {
        margin-right: 5px;

        img {
          height: 55px;
        }
      }

      .score {
        margin-left: 5px;
      }
    }
  }

  .provider-cat-stamp {
    max-width: 300px;

    h2 {
      margin-top: 0px;
    }
  }

  .best-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    h2 {
      margin-top: 0px;
    }

    &.justify-start {
      justify-content: flex-start;
    }

    .best-year-column {
      display: flex;
      flex: 1 0 33%;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    }

    .best-column {
      display: flex;
      flex: 1 0 300px;
      max-width: 300px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
    }
  }

  table.special-mentions {
    background-color: var(--vpsb-background-secondary);

    tr.first-row>th {
      background-color: var(--vpsb-background-soft-primary);
    }

    th {
      font-weight: 600;
    }
  }
}


